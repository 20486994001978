import React from "react";
import { Outlet } from "react-router-dom";
import '../.././styles/View.css';

function View() {

  return (
    <div>
      <Outlet />
    </div>
  );
}

export default View;