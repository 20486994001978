import React, { useEffect } from "react";
import { useParams } from "react-router";
import metadata from "../../utils/IC_metadata.json"; 
import { CONTRACT_ADDRESS } from '../../utils/conf.js';


function Post() {
  let { postSlug } = useParams();

  useEffect(() => {
    // Fetch post using the postSlug
  }, [postSlug]);

  const tokenURL = "../img/" + postSlug + ".html";
  const IPFS = metadata.tokens[postSlug].image.slice(7);

  return (
    <div>
      <div className="p80">
        <div>
          <h1 className="h1-view">
            <a href="https://irregularconditions.art/" target="_self">IRREGULAR CONDITIONS</a>
          </h1>
        </div>
        <div className="pure-g pt40">
          <div className="pure-u-1-1 pure-u-md-1-1 pure-u-lg-1-3">
            <div className="frWrapper">
              <iframe src={tokenURL} title="{postSlug}" frameBorder="0" className="frViews" height="100%" width="100%"></iframe>
            </div>
          </div>
          <div className="pure-u-1-1 pure-u-md-1-1 pure-u-lg-1-3 pl40">
            <h3>
            ID: #{metadata.tokens[postSlug].tokenId}
          </h3>
            <ul className="p2">
              <ol>Traits</ol>
              <li>Border: {metadata.tokens[postSlug].attributes[0].value}</li>
              <li>Shadow: {metadata.tokens[postSlug].attributes[1].value}</li>
              <li>Color: {metadata.tokens[postSlug].attributes[2].value}</li>
              <li>Overlay: {metadata.tokens[postSlug].attributes[3].value}</li>
              <li>Speed: {metadata.tokens[postSlug].attributes[4].value}</li>
            </ul>
            <p className="p2">
              <a href={"https://ipfs.io/ipfs/" + IPFS} target="_blank">Show on IPFS</a><br />
              <a href={"https://etherscan.io/token/" + CONTRACT_ADDRESS + "?a=" + postSlug} target="_blank">Show on Etherscan</a><br />
              <br />
              <a href={"https://opensea.io/assets/ethereum/" + CONTRACT_ADDRESS + "/" + postSlug} target="_blank">View on OpenSea</a><br />
              <a href={"https://looksrare.org/collections/" + CONTRACT_ADDRESS + "/" + postSlug} target="_blank">View on LooksRare</a><br />
            </p>
            <p className="p2">
              —<br />
              Double click on the artwork to enter full-screen.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Post;