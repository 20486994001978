import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { App, View, Post } from './components';

ReactDOM.render(
  
  <React.StrictMode>

    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/view" element={<View />}>
          <Route path=":postSlug" element={<Post />} />
        </Route>
      </Routes>
    </Router>
    
  </React.StrictMode>,

  document.getElementById('root')

);
