import '../styles/WalletUI.css';
import React, { useState, useEffect } from "react";
import '@rainbow-me/rainbowkit/dist/index.css';
import { Connect } from "./connect"; 

import {
  getDefaultWallets,
  RainbowKitProvider,
  lightTheme
} from '@rainbow-me/rainbowkit';
import {
  chain,
  configureChains,
  createClient,
  WagmiConfig,
} from 'wagmi';
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';

const { chains, provider } = configureChains(
  [chain.mainnet],
  [
    infuraProvider({ apiKey: '8221473c21cc4d489d740aef70ec2c1c' }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'My RainbowKit App',
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})


const App = (props) => {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} showRecentTransactions={true} >
        <Connect />
      </RainbowKitProvider>
    </WagmiConfig>
    );
};

export default App;