import '.././styles/App.css';
import '.././styles/pure.css';
import React from 'react';
import Countdown from 'react-countdown';
import WalletUi from './WalletUi';

import { CONTRACT_ADDRESS, PRICE } from '.././utils/conf.js';

const timer = ({ days, hours, minutes, seconds, completed, daysInHours }) => {
  if (completed) {
    // Render a completed state
    return (<WalletUi contract={CONTRACT_ADDRESS} price={PRICE} />);
  } else {
    // Render a countdown
    return <span className="p1">Launch in {days} days, {hours} hours, {minutes} minutes and {seconds} seconds.</span>;
  }
};

const App = () => {
  
  return (
    <div>
      <div className="container">
        <div>
            <h1>IRREGULAR CONDITIONS</h1>
            <p className="p1 inline"> is an eightfold colour story collection created by <a href="https://martinhoura.net/" target="_blank" rel="noreferrer">Martin Houra</a>, which captures an appreciation of controlled chaos. Drawing inspiration from liquid movement, like droplets running down a wall, unique paths and patterns of colour have been translated into 500 semi-generative artworks. Born through an algorithm, each piece has been hand selected, manipulated and overlaid with subtle motion to increase the inherent tension in the work, as they appear to simultaneously desire movement or change whilst remaining completely still. The majority of these captured moments are further confounded by a border that accentuates the image boundaries, where patterns seemingly attempt to break free of the imposed structure.
          </p><br /><br />
          <p className="p1">
            This collection is a two-part series that aims to reflect the artworks’ desire to grow and come to life.
          </p>
          <p className="p1">
              <a href="https://martinhoura.net/" target="_blank" rel="noreferrer">martinhoura.net</a> — 
              <a href="https://twitter.com/martinhoura" target="_blank" rel="noreferrer">Twitter</a>
          </p>
            
        </div>

        <hr /> {/*---------------------------------------------------------------------------------------------------*/}

        <p className="p1">
            Sold out! Secondary pieces might be available on:<br /><br />
            <a href="https://opensea.io/collection/irregular-conditions" target="_blank">OpenSea</a> —&nbsp;
            <a href="https://looksrare.org/collections/0xca6a27BBA7c32E8FfB86dE8C10722e771525D2Ec" target="_blank">LooksRare</a> —&nbsp;
            <a href="https://sudoswap.xyz/#/manage/0xcfdf8556b16e09ca7a7aac191964aa80c225060e" target="_blank">sudoswap</a>

          </p>

        <hr /> {/*---------------------------------------------------------------------------------------------------*/}

        <div className="pure-g">
            <div className="pure-u-1-2 pure-u-md-1-2 pure-u-lg-1-2 p10">
              <h3>INFO & SPECS</h3>
              <ul className="p2">
                <ol>Edition</ol>
                <li>500 artworks</li>
                <li>10 Artist Proofs</li>
                <li>2160x3840px (4K)</li>
                <li>JPG, WebGL</li>
              </ul>
            </div>
            <div className="pure-u-1-2 pure-u-md-1-2 pure-u-lg-1-4 p10">
              <h3>CONTRACT</h3>
              <p className="p2">
                Name: Irregular Conditions<br />
                Token: IC<br />
                <br />
                Blockchain: Ethereum<br />
                Contract: <a href="https://etherscan.io/address/0xca6a27bba7c32e8ffb86de8c10722e771525d2ec" target="_blank">View on Etherscan</a><br />
                Contract type: ERC-721A<br />
              </p>
            </div>
          </div>

        <hr /> {/*---------------------------------------------------------------------------------------------------*/}
        
        <div className="pure-g">
          <div className="pure-u-1-1 pure-u-md-1-2 pure-u-lg-1-3">
            <div className="frWrapper">
              <iframe src="img/72.html" title="72" frameBorder="0" className="fr" height="100%" width="100%"></iframe>
            </div>
          </div>
          <div className="pure-u-1-1 pure-u-md-1-2 pure-u-lg-1-3">
            <div className="frWrapper">
              <iframe src="img/369.html" title="369" frameBorder="0" className="fr" height="100%" width="100%"></iframe>
            </div>
          </div>
          <div className="pure-u-1-1 pure-u-md-1-2 pure-u-lg-1-3">
            <div className="frWrapper">
              <iframe src="img/9.html" title="9" frameBorder="0" className="fr" height="100%" width="100%"></iframe>
            </div>
          </div>
          <div className="pure-u-1-1 pure-u-md-1-2 pure-u-lg-1-3">
            <div className="frWrapper">
              <iframe src="img/462.html" title="462" frameBorder="0" className="fr" height="100%" width="100%"></iframe>
            </div>
          </div>
          <div className="pure-u-1-1 pure-u-md-1-2 pure-u-lg-1-3">
            <div className="frWrapper">
              <iframe src="img/244.html" title="244" frameBorder="0" className="fr" height="100%" width="100%"></iframe>
            </div>
          </div>
          <div className="pure-u-1-1 pure-u-md-1-2 pure-u-lg-1-3">
            <div className="frWrapper">
              <iframe src="img/37.html" title="37" frameBorder="0" className="fr" height="100%" width="100%"></iframe>
            </div>
          </div>
        </div>
        
        <hr /> {/*---------------------------------------------------------------------------------------------------*/}

        <div className="pure-g">
          <div className="pure-u-1-2 pure-u-md-1-2 pure-u-lg-1-3 p10">
            <h3>TRAITS</h3>
            <ul className="p2">
              <ol>Colors</ol>
              <li>Graphite</li>
              <li>Brink</li>
              <li>Argon</li>
              <li>Scarlet</li>
              <li>Flax</li>
              <li>Vermilion</li>
              <li>Emerald</li>
              <li>Lapis</li>
            </ul>
          </div>
          <div className="pure-u-1-2 pure-u-md-1-2 pure-u-lg-1-3 p10">
            <h3>&nbsp;</h3>
            <ul className="p2">
              <ol>Borders</ol>
              <li>0X</li>
              <li>1X</li>
              <li>2X</li>
              <li>3X</li>
            </ul>
            <ul className="p2">
              <ol>Overlay-Type</ol>
              <li>Flat</li>
              <li>Wrap</li>
              <li>Glass</li>
            </ul>
          </div>
          <div className="pure-u-1-2 pure-u-md-1-2 pure-u-lg-1-3 p10">
            <h3>&nbsp;</h3>
            <ul className="p2">
              <ol>Overlay-Shadow</ol>
              <li>Sharp</li>
              <li>Medium</li>
              <li>Soft</li>
            </ul>
            <ul className="p2">
              <ol>Overlay-Speed</ol>
              <li>Slow</li>
              <li>Mid</li>
              <li>Fast</li>
            </ul>
          </div>
        </div>

        <hr /> {/*---------------------------------------------------------------------------------------------------*/}

        <div className="pure-g">
          <div className="pure-u-1-1 pure-u-md-1-2">
            <div className="frWrapper maxHeight">
              <iframe src="img/278.html" title="278" frameBorder="0" className="fr" height="100%" width="100%"></iframe>
            </div>
          </div>
          <div className="pure-u-1-1 pure-u-md-1-2">
            <h2>IRREGULAR MOVEMENTS</h2>
            <p className="p1">
              Calls on technical collaborators to convert the primary image series into time-based generative and deterministic artworks that are running in real-time. Each artwork will be programmatically created at mint, setting the type, structure (and other various elements) as well as the overall speed in which each artwork evolves—starting from a fully formed image and ending in an unrecognizable version of itself. The simulation speed will be extremely slow and the full simulation length will range from months to years.
            </p>
            <p className="p1">
              IRREGULAR CONDITIONS is an ongoing project that will continue to evolve and expand over the course of 2023/24. Currently IRREGULAR MOVEMENTS is in the planning phase and will rely on collaborators for completion.
            </p>
          </div>
        </div>
        
        <hr /> {/*---------------------------------------------------------------------------------------------------*/}

        <div className="pure-g">
          <div className="pure-u-1-1 pure-u-md-1-2">
          <h2>ABOUT THE ARTIST</h2>
            <p className="p1">
            <a href="https://martinhoura.net/" target="_blank" rel="noreferrer">Martin Houra</a> is a CGI artist and Creative Director with an appreciation for unusual, almost scientific, details and abstract techniques. With an unwavering passion for image manipulation, pattern generation and at times the merging of sound waves to bring these techniques to life, he has a highly disciplined, dedicated approach to creating artworks in a cathartic and melodic rhythm outside of commercial demands. Fascinated by liquid and fragments of organic matter, Martin looks to capture moments in time in macro-microscopic form - drawn to synthetic works made alive through coding and additional layers of artistry.
          </p>
          <p className="p1">
            With over 15 years experience in 3D and creative realms, the crypto universe presents an exciting opportunity for Martin’s artistic abilities to come to light and establish a growing catalogue of contemporary works. Now on his third collection, his artworks continue to showcase not only technical command, but emotive highly expressive imagery that reflects the deep connection he has to his surroundings, full of life and movement, down to the tiniest detail.
          </p>
          </div>
          <div className="pure-u-1-1 pure-u-md-1-2">
            <div className="frWrapper">
                <iframe src="img/56.html" title="56" frameBorder="0" className="fr" height="100%" width="100%"></iframe>
            </div>
          </div>
        </div>

        <hr /> {/*---------------------------------------------------------------------------------------------------*/}

        <p className="pb160 p2">
          <a href="https://martinhoura.net/" target="_blank" rel="noreferrer" className="footer">© 2022 / Martin Houra / martinhoura.net</a>
        </p>

      </div>
    </div>
  );
};

export default App;