// import IrregularConditionsABI from './IrregularConditions_edited_ABI.json';
// const CONTRACT_ADDRESS = '0xf345B2Ec5314d9ef9561d4d7940cB4E75A9fAA39';

import IrregularConditionsABI from '../utils/IrregularConditions_rkb_ABI.json';
const CONTRACT_ADDRESS = '0xca6a27BBA7c32E8FfB86dE8C10722e771525D2Ec';

const PRICE = "0.02";

const irregularConditionsContract = {
    addressOrName: CONTRACT_ADDRESS,
    contractInterface: IrregularConditionsABI,
  }

export { irregularConditionsContract as default, CONTRACT_ADDRESS, PRICE  };